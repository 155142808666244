import * as amplitude from '@amplitude/analytics-browser';
import { AnalyticsAdapter, EventProperties, UserProperties } from './types';

export class AmplitudeAdapter implements AnalyticsAdapter {
  private initialized: boolean = false;

  initialize(apiKey: string, options: amplitude.Types.BrowserOptions): void {
    if (!this.initialized) {
      amplitude.init(apiKey, {
        ...options
      });
      this.initialized = true;
      console.log('Amplitude analytics initialized');
    }
  }

  setUserId(userId: string | null): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    if (!userId) {
      console.warn('User ID not provided');
      return;
    }
    amplitude.setUserId(userId);
  }

  trackEvent(eventName: string, eventProperties: EventProperties = {}): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    amplitude.track(eventName, eventProperties);
  }

  setUserProperties(properties: UserProperties): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }

    amplitude.identify(
      Object.entries(properties).reduce((identify, [key, value]) => {
        if (value !== null) {
          identify.set(key, value);
        }
        return identify;
      }, new amplitude.Identify())
    );
  }

  setGroup(groupType: string, groupName: string | string[]): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    amplitude.setGroup(groupType, groupName);
  }

  setUserPathInLocalStorage(userPath: string): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    localStorage.setItem('user_path', userPath);
  }

  getUserPathFromLocalStorage(): string {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return '';
    }
    return localStorage.getItem('user_path') || '';
  }
}
