/* eslint-disable no-param-reassign */
import Clarity from '@microsoft/clarity';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enableClarity } from 'infrastructure/serviceConfigs/clarityConfig';
import analyticsService from 'infrastructure/serviceHandlers/Analytics/Analytics';
import { GetUserDetails } from 'services/api/UserApi';
import { TUserResponseType, TUserState } from 'typings/userTypes';
import {
  removeRequestHeaders,
  setLocalStorageItem
} from 'utils/commonFunctions';
import i18next, { getLanguage } from '../../i18n/i18n';

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (payload: string, thunkAPI) => {
    try {
      // console.log('fetch user pyaload', payload);
      const response = await GetUserDetails(payload);
      // console.log('GetUserDetails Response:', response);
      const responseData = response.data;
      if (responseData.id) analyticsService.setUserId(responseData.id);
      if (enableClarity && responseData && responseData.id)
        Clarity.identify(
          responseData.id,
          '',
          '',
          // `${responseData.name.first} ${responseData.name.last}`
          responseData.id
        );
      if (responseData.language) {
        const userLanguage = getLanguage(responseData.language);
        i18next.changeLanguage(userLanguage);
      }
      setLocalStorageItem('userId', responseData.id as string);
      return responseData;
    } catch (error) {
      console.error('Error in GetUserDetails:', error);
      return thunkAPI.rejectWithValue('Failed to fetch user details');
    }
  }
);

const initialUserState: TUserState = {
  userDetails: null,
  isLoading: 'idle',
  isAuthenticated: false,
  isEligibleForTrial: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    clearUser: (state) => {
      state.userDetails = null;
      state.isLoading = 'idle';
      state.isAuthenticated = false;
      state.isEligibleForTrial = false;
      removeRequestHeaders();
    },
    updateFreeTrialEligibility: (state, action) => {
      state.isEligibleForTrial = action.payload || false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = 'succeeded';
        state.userDetails = action.payload as TUserResponseType;
        state.isAuthenticated = true;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = 'pending';
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = 'failed';
        state.isAuthenticated = false;
      });
  }
});

// Action creators are generated for each case reducer function
export const { clearUser, updateFreeTrialEligibility } = userSlice.actions;

export default userSlice.reducer;
