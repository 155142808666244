import { AmplitudeAdapter } from './Amplitude';
import { AppEvent, eventNameMap, mapEventProperties } from './eventMapper';
import {
  AnalyticsAdapter,
  EventProperties,
  InitializeOptions,
  UserProperties
} from './types';

class AnalyticsService {
  private adapter: AnalyticsAdapter;

  private initialized: boolean = false;

  constructor(adapter: AnalyticsAdapter) {
    this.adapter = adapter;
  }

  initialize(apiKey: string, options: InitializeOptions): void {
    this.adapter.initialize(apiKey, options);
    this.initialized = true;
  }

  setAdapter(adapter: AnalyticsAdapter): void {
    this.adapter = adapter;
    this.initialized = false;
  }

  setUserId(userId: string | null): void {
    if (!this.initialized) {
      console.warn('Analytics service not initialized');
      return;
    }
    this.adapter.setUserId(userId);
  }

  trackEvent(eventName: AppEvent, eventProperties: EventProperties = {}): void {
    if (!this.initialized) {
      console.warn('Analytics service not initialized');
      return;
    }

    // Map standardized event to service-specific event name
    const serviceEventName = eventNameMap[eventName];

    // Transform properties for the specific service if needed
    const mappedProperties = mapEventProperties(eventName, eventProperties);

    this.adapter.trackEvent(serviceEventName, mappedProperties);
  }

  setUserProperties(properties: UserProperties): void {
    if (!this.initialized) {
      console.warn('Analytics service not initialized');
      return;
    }
    this.adapter.setUserProperties(properties);
  }

  setGroup(groupType: string, groupName: string | string[]): void {
    if (!this.initialized) {
      console.warn('Analytics service not initialized');
      return;
    }

    if (this.adapter.setGroup) {
      this.adapter.setGroup(groupType, groupName);
    } else {
      console.warn(
        'Group functionality not supported by current analytics adapter'
      );
    }
  }

  setUserPathInLocalStorage(userPath: string): void {
    if (!this.initialized) {
      console.warn('Analytics service not initialized');
      return;
    }

    if (this.adapter.setUserPathInLocalStorage) {
      this.adapter.setUserPathInLocalStorage(userPath);
    } else {
      console.warn(
        'User path functionality not supported by current analytics adapter'
      );
    }
  }

  getUserPathFromLocalStorage(): string {
    if (!this.initialized) {
      console.warn('Analytics service not initialized');
      return '';
    }

    if (this.adapter.getUserPathFromLocalStorage) {
      return this.adapter.getUserPathFromLocalStorage();
    }
    console.warn(
      'User path functionality not supported by current analytics adapter'
    );
    return '';
  }
}

// Create a singleton instance with Amplitude adapter as default
const analyticsService = new AnalyticsService(new AmplitudeAdapter());
export default analyticsService;
export { AppEvent };
