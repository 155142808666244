/* eslint-disable import/order */
// Core & Essential Dependencies
import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';

// MUI Components & Theme
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// Project Configs & Contexts
import { ConfigContext } from 'context';
import AppThemeProvider from 'themes/AppThemeProvider';

// Utility Functions & Analytics
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import InstaLoader from 'components/LoaderComponent/InstaLoader';
import ScrollToTop from 'components/ScrollFromTop';
import PaymentMethodScreen from 'pages/PaymentMethodScreen';
import paymentStatus from 'pages/paymentStatus/paymentStatus';
import { getPartnerConfiguration, getPartnerName } from 'partner_configuration';
import { useTranslation } from 'react-i18next';
import AddPaymentMethods from './components/BillingAndInvoices/addPaymentMethods';
import BillingAndInvoiceSummary from './components/BillingAndInvoices/billingAndInvoicesSummary';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import BuySubscription from 'pages/buySubscription';
import UpgradeSubscription from 'pages/upgradeSubscription';
import InternetStatusRoute from 'pages/InternetStatusRoute';

// Privacy Policy and Terms of Service pages
const PrivacyPolicy = React.lazy(
  () => import('pages/privacyPolicy/PrivacyPolicy')
);
const TermsOfService = React.lazy(
  () => import('pages/TermsAndService/TermsAndServices')
);

// Authentication-related pages
const LoginScreen = React.lazy(() => import('./pages/LoginScreen/LoginScreen'));

// Public-facing application entry points
const LaunchApp = React.lazy(() => import('pages/LaunchApp'));

// 404 Not Found page
const NotFound = React.lazy(() => import('pages/NotFound/NotFound'));

// User account overview and settings
const UserAccount = React.lazy(() => import('pages/userAccount'));

const CheckoutSession = React.lazy(
  () => import('components/CheckoutSession/CheckoutSession')
);

// Account deletion request and verification
const AccountDeletionRequest = React.lazy(
  () => import('pages/AccountDeletion/request')
);
const VerifyDeleteRequest = React.lazy(
  () => import('pages/AccountDeletion/verifyRequest')
);

// List and details of subscriptions
const SubscriptionList = React.lazy(
  () => import('pages/subscriptions/subscriptionList')
);
const SubscriptionsDetails = React.lazy(
  () => import('pages/subscriptions/subscriptionDetails')
);

// Subscription actions: purchasing and upgrading

const BasicUpgrade = React.lazy(() => import('pages/basicUpgrade'));

// Review and confirm purchase flows
const ReviewPurchase = React.lazy(() => import('pages/reviewPurchase'));

// Overview of available plans
const OurPlans = React.lazy(() => import('pages/ourPlans'));

// Adding and managing cameras
const AddCameraInfo = React.lazy(() => import('pages/AddCameraInfo'));
// const SingleCameraAdded = React.lazy(() => import('pages/singleCameraAdded'));
// const MultiCameraAdded = React.lazy(() => import('pages/multiCameraAdded'));

// Features like skipping a trial or explaining skipping benefits
const WhySkip = React.lazy(() => import('pages/whySkip/whySkip'));
const SkippingTrial = React.lazy(
  () => import('pages/SkippingTrial/SkippingTrial')
);

// Feature showcase or promotions
const Features = React.lazy(() => import('pages/promotion/index'));
const PromotionTestComponent = React.lazy(
  () => import('pages/promotion/PromotionNewTestComponent')
);

// Main dashboard for logged-in users
const Dashboard = React.lazy(() => import('pages/dashboard'));

// Device and partner-specific bridge
const Bridge = React.lazy(() => import('pages/Bridge'));

const MaxCameraCountReached = React.lazy(
  () => import('pages/maxCameraCountReached')
);

const CustomerSupport = React.lazy(() => import('pages/customerSupport'));

const FAQPage = React.lazy(() => import('pages/faqPage/faqPage'));
const cancellationRefundPolicy = React.lazy(
  () => import('pages/cancellationRefundPolicy')
);

const appBridgeLazyLoaded = React.lazy(
  () => import('pages/appBridge/appBridge')
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const { t: translate } = useTranslation('translation', {
    keyPrefix: 'partnerConfig'
  });
  const url = window.location.origin;
  // console.log('url::', url);
  const partnerName = getPartnerName(url);
  // console.log(':: partnerName', partnerName);
  // const dispatch = useAppDispatch();
  // const { isAuthenticated } = useAppSelector((state) => state.user);
  // const location = useLocation();
  const config = getPartnerConfiguration(url, translate);
  // console.log('config:: ', config);
  // test
  const theme = AppThemeProvider(partnerName);

  return (
    <div className="App" style={{ height: '100vh' }}>
      <Sentry.ErrorBoundary>
        <ErrorBoundary>
          <ScrollToTop />
          <ConfigContext.Provider value={config}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Suspense fallback={<InstaLoader />}>
                <SentryRoutes>
                  <Route element={<InternetStatusRoute />}>
                    <Route path="launchMobileApp" Component={LaunchApp} />
                    <Route path="login" Component={LoginScreen} />
                    <Route
                      path="requestDeleteServices"
                      Component={AccountDeletionRequest}
                    />
                    <Route
                      path="verifyDeleteServices"
                      Component={VerifyDeleteRequest}
                    />
                    <Route path="/app-bridge" Component={appBridgeLazyLoaded} />
                    <Route path="/privacy-policy" Component={PrivacyPolicy} />
                    <Route
                      path="/terms-of-service"
                      Component={TermsOfService}
                    />
                    <Route path="bridge" Component={Bridge} />
                    <Route element={<PrivateRoute />}>
                      <Route path="/dashboard" Component={Dashboard} />
                      <Route
                        path="/camera-limit-reached"
                        Component={MaxCameraCountReached}
                      />
                      <Route
                        path="/frequently-asked-questions"
                        Component={FAQPage}
                      />
                      ,
                      <Route
                        path="/help-and-support"
                        Component={CustomerSupport}
                      />
                      <Route
                        path="/cancellation-refund-policy"
                        Component={cancellationRefundPolicy}
                      />
                      <Route path="/skipTrial" element={<SkippingTrial />} />
                      <Route path="/promotion" Component={Features} />
                      <Route
                        path="/promotion-alt"
                        Component={PromotionTestComponent}
                      />
                      <Route
                        path="requestDeleteServices"
                        Component={AccountDeletionRequest}
                      />
                      <Route
                        path="verifyDeleteServices"
                        Component={VerifyDeleteRequest}
                      />
                      {/* <Route
                      path="/singleCameraAdded"
                      element={<SingleCameraAdded />}
                    />
                    <Route
                      path="/multiCameraAdded"
                      element={<MultiCameraAdded />}
                    /> */}
                      <Route
                        path="/checkout-session"
                        Component={CheckoutSession}
                      />
                      <Route
                        path="/paymentMethods"
                        Component={PaymentMethodScreen}
                      />
                      <Route
                        path="/reviewPurchase"
                        Component={ReviewPurchase}
                      />
                      <Route path="/ourPlans" Component={OurPlans} />
                      <Route
                        path="/payment-success"
                        Component={paymentStatus}
                      />
                      <Route path="/addCameraInfo" Component={AddCameraInfo} />
                      <Route
                        path="/billings"
                        Component={BillingAndInvoiceSummary}
                      />
                      <Route
                        path="/billing"
                        Component={BillingAndInvoiceSummary}
                      />
                      <Route
                        path="/billings/new"
                        Component={AddPaymentMethods}
                      />
                      <Route path="/userAccount" Component={UserAccount} />
                      <Route
                        path="/buySubscription"
                        Component={BuySubscription}
                      />
                      <Route
                        path="/subscriptions"
                        Component={SubscriptionList}
                      />
                      <Route
                        path="/subscription/:id"
                        Component={SubscriptionsDetails}
                      />
                      <Route path="/whySkipTrial" Component={WhySkip} />
                      <Route path="/upgradeBasic" Component={BasicUpgrade} />
                      <Route
                        path="/subscription/:id/upgrade"
                        Component={UpgradeSubscription}
                      />
                      <Route path="*" Component={NotFound} />
                    </Route>
                    <Route path="*" Component={LoginScreen} />
                  </Route>
                </SentryRoutes>
              </Suspense>
            </ThemeProvider>
          </ConfigContext.Provider>
        </ErrorBoundary>
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
