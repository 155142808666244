/* eslint-disable object-shorthand */
import { Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import PlanComparisonComponent from 'components/PlanComparisionScreen/PlanComparisionComponent';
import PlanPageLoader from 'components/PlanPageLoader/PlanPageLoader';
import { homeSecurityPortalApi } from 'data/axios';
import analyticsService, {
  AppEvent
} from 'infrastructure/serviceHandlers/Analytics/Analytics';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { endpoints } from 'services/api/endpoints';
import { getPlanDetails } from 'services/api/plansApi';
import {
  checkFreeTrialEligibility,
  getSubscriptionDetails
} from 'services/api/subscriptionApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchAllSubscription,
  setSelectedSubscriptionInfo
} from 'store/reducers/subscriptionReducer';
import {
  SUBSCRIPTION_TYPE,
  TPlanList,
  TPurchasePayload,
  TSubscriptionType
} from 'typings';
import { FREE_TRIAL_DURATION } from 'utils/constants';
import {
  APP_BUTTON_TYPES,
  isRunningInWebView,
  showAppButton
} from 'utils/webviewHelpers';

function BuySubscription() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.subscription.buySubscription'
  });

  const { isUpgrade = false, deviceId } = location?.state || {
    isUpgrade: false,
    deviceId: ''
  };

  const spaceData = useAppSelector((state) => state.space.spaceData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [allPlans, setAllPlans] = useState<TPlanList[]>([]);
  const [userSubscriptions, setUserSubscriptions] = useState<
    TSubscriptionType[]
  >([]);
  const [selectedCyclesPlans, setSelectedCyclesPlans] = useState<TPlanList[]>(
    []
  );
  const [isFreeTrial, setFreeTrial] = useState(false);

  useEffect(() => {
    if (spaceData[0]?.id) {
      setError(null);
      setIsLoading(true);

      Promise.all([
        getPlanDetails(),
        checkFreeTrialEligibility(),
        getSubscriptionDetails()
      ])
        .then((res) => {
          const [allPlansResponse, userFreeTrial, subscriptionDetails] = res;
          console.log('trial response', userFreeTrial);
          setAllPlans(allPlansResponse.data);
          setUserSubscriptions(subscriptionDetails.data);
          setFreeTrial(userFreeTrial.data.trial);
        })
        .catch((err) => {
          // debugger;
          console.log('err', err);
          setError(
            err?.response?.data?.message ||
              'There was an error loading the subscription plans. Please try reloading the page.'
          );
          setIsLoading(false);
          Sentry.captureException(err, {
            tags: {
              plan_details_error: 'fetching_plan_details_error'
            },
            extra: {
              errorMessage: err?.message,
              errorCode: err?.code
            }
          });
        })
        .finally(() => {});
    }
  }, [spaceData]);

  useEffect(() => {
    if (isRunningInWebView()) {
      // Pairing Flow
      if (deviceId && isFreeTrial) {
        showAppButton(APP_BUTTON_TYPES.RELOAD, APP_BUTTON_TYPES.BACK);
      } else if (deviceId && !isFreeTrial) {
        showAppButton(APP_BUTTON_TYPES.RELOAD, APP_BUTTON_TYPES.BACK);
      } else {
        showAppButton(APP_BUTTON_TYPES.RELOAD, APP_BUTTON_TYPES.CLOSE);
      }
    }
  }, [isRunningInWebView, deviceId, isFreeTrial]);

  const filterPlansForCycle = (isYearlyCycle: boolean) => {
    const cycleMonths = isYearlyCycle ? 12 : 1;
    const cyclePlans = allPlans.filter(
      (el: TPlanList) => el.subscription_cycle === cycleMonths
    );
    // debugger
    cyclePlans.sort(
      (a, b) => b.prices[0].original_price - a.prices[0].original_price
    );
    setSelectedCyclesPlans(cyclePlans);
    setIsLoading(false);
  };

  useEffect(() => {
    if (allPlans.length) {
      filterPlansForCycle(true);
    }
  }, [allPlans]);

  const createPurchaseId = (plan: TPlanList) => {
    const eligibleForFreeTrial =
      userSubscriptions[0]?.subscription_type === SUBSCRIPTION_TYPE.PREVIEW
        ? false
        : isFreeTrial;
    // debugger;
    const payload: TPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: eligibleForFreeTrial,
      space_id: spaceData[0]?.id
    };

    homeSecurityPortalApi
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        dispatch(
          setSelectedSubscriptionInfo({
            isUpgrade: isUpgrade || payload['action'] === 'Upgrade',
            isFreeTrial: isFreeTrial,
            planSelected: plan,
            purchaseId: res.data.purchase_id,
            deviceId
          })
        );
        navigate('/checkout-session');
      })
      .catch((err) => console.log('purchases err', err));
  };

  const findUserType = () => {
    let userType = '';
    if (isFreeTrial && userSubscriptions.length === 0) {
      userType = 'free_trial_eligible_user';
    } else if (isFreeTrial && userSubscriptions.length !== 0) {
      userType = 'free_trial_user';
    } else if (!isFreeTrial && userSubscriptions.length === 0) {
      userType = 'free_user';
    } else {
      userType = 'subscribed_user';
    }
    return userType;
  };

  useEffect(() => {
    dispatch(fetchAllSubscription());
    analyticsService.trackEvent(AppEvent.SCREEN_VIEW_PLAN_LIST, {
      user_type: findUserType()
    });
    // console.log('SCREEN_VIEW_PLAN_LIST',findUserType());
  }, []);

  const handlePlanSelection = (el: TPlanList) => {
    createPurchaseId(el);
  };

  if (isLoading) {
    return <PlanPageLoader canBuyPlan />;
  }

  if (error) {
    return (
      <Stack alignItems="center" marginY={8}>
        <Typography color="error">
          {error ||
            'There was an error loading the plans. Please reload the page.'}
        </Typography>
      </Stack>
    );
  }

  if (
    !isLoading &&
    (allPlans.length === 0 || selectedCyclesPlans.length === 0)
  ) {
    return (
      <Stack pt={2.5}>
        <Typography
          variant="h2"
          sx={{ textTransform: 'capitalize', textAlign: 'center' }}
        >
          {t('noPlansAvailable')}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      {/* If user is in preview then show this  */}
      {isFreeTrial &&
        userSubscriptions[0]?.subscription_type ===
          SUBSCRIPTION_TYPE.PREVIEW && (
          <Typography
            variant="h2"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            {t('afterPreviewBuyPlanHeader')}
          </Typography>
        )}
      {/* If user is is eligible for free trial and its not upgrade and not preview plan then show this */}
      {isFreeTrial &&
        userSubscriptions[0]?.subscription_type !==
          SUBSCRIPTION_TYPE.PREVIEW && (
          <>
            <Typography variant="h2" sx={{ textAlign: 'center' }}>
              {/* {`Choose your ${FREE_TRIAL_DURATION} days Free Trial plan`} */}
              {t('chooseTrialPlanDays')}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                textTransform: 'capitalize',
                textAlign: 'center',
                color: 'background.orange'
              }}
            >
              {/* {`Choose your ${FREE_TRIAL_DURATION} days Free Trial plan`} */}
              {t('freeTrialDays', {
                duration: FREE_TRIAL_DURATION
              })}
            </Typography>
          </>
        )}
      {/* If user is not eligible for free trial and its not upgrade => Means user buy an actual plan */}
      {!isFreeTrial && (
        <Typography
          variant="h2"
          sx={{ textTransform: 'capitalize', textAlign: 'center' }}
        >
          {t('afterPreviewBuyPlanHeader')}
        </Typography>
      )}

      {!isLoading && allPlans.length > 0 && selectedCyclesPlans.length > 0 && (
        <PlanComparisonComponent
          allPlans={allPlans}
          plansData={selectedCyclesPlans}
          allowSelection
          onPlanSelection={handlePlanSelection}
          isFreeTrial={isFreeTrial}
          isUpgrade={isUpgrade}
          onChange={filterPlansForCycle}
        />
      )}
    </Stack>
  );
}

export default BuySubscription;
