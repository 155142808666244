/* eslint-disable @typescript-eslint/no-use-before-define */
import { Stack, styled as styledMUI } from '@mui/material';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import SideBar from 'components/SideBar/SideBar';
import TopBar from 'components/TopBar/TopBar';
import { isRunningInWebView } from 'utils/webviewHelpers';

interface ContentContainerProps {
  customStyling: boolean;
}

const ContentContainer = styledMUI('div')<ContentContainerProps>(
  ({ theme, customStyling }) => ({
    [theme.breakpoints.down('sm')]: {
      padding: customStyling ? '0px' : '20px',
      minHeight: '100vh'
    },
    [theme.breakpoints.up('sm')]: {
      width: '320px',
      margin: customStyling ? '0px auto 0 auto' : '20px auto 0 auto'
    },
    [theme.breakpoints.up('md')]: {
      width: '440px'
    }
  })
);

const noSideBarAllowedRoutes = ['/addCameraInfo'];
const paymentRoutes = ['/checkout-session', '/payment-success'];

function PrivateRoute() {
  const location = useLocation();
  const isWebViewPresent = isRunningInWebView();
  console.log('location - ', location);
  // console.log(
  //   'noSideBarAllowedRoutes.includes(location.pathname) - ',
  //   noSideBarAllowedRoutes.includes(location.pathname)
  // );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isAuthenticated } = useSelector((state: any) => state.user);
  const [openSideBar, setOpenSideBar] = useState(!isMobile);

  if (!isAuthenticated && !isWebViewPresent) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }
  const isCustomRoute = paymentRoutes.includes(location.pathname);

  // authorized so return child components
  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
        backgroundColor: (theme) => `${theme.palette.background.default}`
      }}
    >
      {openSideBar && (
        <SideBar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
      )}
      <Stack
        sx={{
          width:
            openSideBar &&
            !isMobile &&
            !noSideBarAllowedRoutes.includes(location.pathname)
              ? 'calc(100% - 340px)'
              : '100%',
          marginLeft:
            openSideBar &&
            !isMobile &&
            !noSideBarAllowedRoutes.includes(location.pathname)
              ? '340px'
              : 0,
          height: '100%',
          flexDirection: 'column'
        }}
      >
        {!isWebViewPresent && (
          <TopBar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
        )}
        <ContentContainer customStyling={isCustomRoute}>
          <Outlet />
        </ContentContainer>
      </Stack>
    </Stack>
  );
}

export default PrivateRoute;
