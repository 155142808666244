import envConfig from 'configs/envConfig';
import analyticsService from 'infrastructure/serviceHandlers/Analytics/Analytics';

export const enableAmplitude =
  envConfig('env') === 'dev' ||
  envConfig('env') === 'prod' ||
  envConfig('env') === 'staging' ||
  envConfig('env') === 'luna-stg' ||
  envConfig('env') === 'luna';

const amplitudeKey = envConfig('amplitudeKey');
if (amplitudeKey && enableAmplitude) {
  analyticsService.initialize(amplitudeKey, {
    autocapture: true
  });
}
